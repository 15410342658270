import React, { useEffect, useState, useRef } from "react";
import { Carousel } from "primereact/carousel";
export const HomeProduct = () => {
  //const [products, setProducts] = useState([]);
    const products = [
        {
            id: '1000',
            name:'buskat',
            head: '01/02',
            title: 'Live Trading',
            connt: 'Live trading involves actively buying and selling financial instruments in real-time markets to capitalise on price fluctuations.',
            image: 'livetrading.webp'
        },
        {
            id: '1000',
            head: '02/02',
            title: 'Real State Trading',
            connt: 'Real estate trading involves buying real estate with the intention of only holding it for a short period of time, in order to look to sell it later for a profit',
            image: 'realstate.webp'
        }
    ]
  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const productTemplate = (product) => {
    return (
      <div className="border-1 surface-border border-round m-2 py-5 px-3">
        <div className="mb-3">
            <div className="grid">
            <div className="col-7">
                <h3>{product.head}</h3>
                <h3>{product.title}</h3>
                <p>{product.connt}</p>
            </div>
            <div className="col-5">
            <img
            src={`/images/${product.image}`}
            alt={product.name}
            className="shadow-2"
            width="100%"
          />
            </div>
            </div>
          
        </div>
        {/* <div>
          <h4 className="mb-1">{product.name}</h4>
          <h6 className="mt-0 mb-3">${product.price}</h6>
          <Tag
            value={product.inventoryStatus}
            severity={getSeverity(product)}
          ></Tag>
          <div className="mt-5 flex flex-wrap gap-2 justify-content-center">
            <Button icon="pi pi-search" className="p-button p-button-rounded" />
            <Button
              icon="pi pi-star-fill"
              className="p-button-success p-button-rounded"
            />
          </div>
        </div> */}
      </div>
    );
  };
  return (
    <div className="card">
      <Carousel
        value={products}
        numScroll={1}
        numVisible={1}
        responsiveOptions={responsiveOptions}
        itemTemplate={productTemplate}
      />
    </div>
  );
};
