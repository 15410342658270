import React, { useEffect, useState, useRef } from "react";
import "./usersprofile.scss"
import axios from "axios";
import { Card } from "primereact/card";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import moment from "moment";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
export const UsersProfile = () => {
    const [users, setUsers] = useState();
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getuserprofile", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setUsers(resp.data.users);
      }
    };
    fetchData();
  }, []);
  if (!users) return false;
  var rows = [];
  for (let i = 0; i < users.length; i++) {
    rows.push(users[i]);
  }
  return (
    <div className="usersprofile">
        <Card>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">SL. No.</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">PAN</TableCell>
                <TableCell align="center">AADHAAR</TableCell>
                <TableCell align="center">BANK</TableCell>
                <TableCell align="center">ACC. NO.</TableCell>
                <TableCell align="center">BRANCH</TableCell>
                <TableCell align="center">IFSC</TableCell>
                <TableCell align="center">EDIT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.tranid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">
                    {moment(row.creation_date).format("YY-MMM-DD")}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.user_name}</TableCell>
                  <TableCell align="left">{row.pan}</TableCell>
                  <TableCell align="left">{row.aadhar}</TableCell>
                  <TableCell align="left">{row.bank}</TableCell>
                  <TableCell align="left">{row.accno}</TableCell>
                  <TableCell align="left">{row.branch}</TableCell>
                  <TableCell align="left">{row.ifsc}</TableCell>
                  <TableCell align="left"><Link to={`/admin/editprofile/${row.email}`}>Edit</Link></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  )
}
