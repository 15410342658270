import React, { useState, useRef, useEffect } from "react";
import "./manageweekly.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import axios from "axios";
export const ManageWeekly = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({
    totalIncome: 0,
    totalTransfer: 0,
  });
  const email = localStorage.getItem("triconixemail");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getweekly",
        {
          email,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      setData({
        totalIncome: resp.data.totalIncome,
        totalTransfer: resp.data.transfer,
      });
    };
    fetchData();
  }, []);
  const maketransfer = async () => {
    const resp = await axios.post(
      url + "/user/weeklytransfer",
      {
        email,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
  };
  return (
    <div className="manageweekly">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
          <Card title="Weekly Details">
            <div className="totalIncome">
              <div className="grid">
                <div className="col-6">Total Income</div>
                <div className="col-6 text-right">{data.totalIncome}</div>
              </div>
            </div>
            <div className="totalTransfer">
              <div className="grid">
                <div className="col-6">Total Transfered</div>
                <div className="col-6 text-right">{data.totalTransfer}</div>
              </div>
            </div>
            <div className="totalReamain">
              <div className="grid">
                <div className="col-6">Total Remain</div>
                <div className="col-6 text-right">
                  {data.totalIncome - data.totalTransfer}
                </div>
              </div>
            </div>
            <Button label="Transfer" onClick={() => maketransfer()} />
          </Card>
        </div>
      </div>
    </div>
  );
};
