// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/hero_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incdetails {
  background: linear-gradient(180deg, rgba(102, 234, 208, 0.5764705882), rgba(177, 252, 225, 0) 100%), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  padding: 60px 5px;
}
.incdetails .hero .content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.incdetails .hero .content .data {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: linear-gradient(180deg, rgba(102, 234, 208, 0.5764705882), rgba(177, 252, 225, 0) 100%);
  border: 1px dashed #000;
  border-radius: 10px;
  padding: 10px;
}
.incdetails .hero .content .data1 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: linear-gradient(180deg, rgba(225, 234, 102, 0.5764705882), rgba(235, 226, 129, 0.336) 100%);
  border-radius: 10px;
  padding: 10px;
  border: 1px dashed #000;
}
@media (max-width: 900.1px) {
  .incdetails .hero {
    padding: 5px;
  }
  .incdetails .hero .content {
    padding: 10px 10px;
    border-radius: 8px;
  }
  .incdetails .hero .content .msg {
    font-size: 34px;
  }
  .incdetails .hero .content .quickaction {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .incdetails .hero .content .quickaction .data {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/user/incomedetails/incomedetails.scss"],"names":[],"mappings":"AAAA;EACE,4IAAA;EAEA,iBAAA;AAAF;AAEI;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAAN;AACM;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mGAAA;EAKA,uBAAA;EACA,mBAAA;EACA,aAAA;AAHR;AAKM;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,uGAAA;EAKA,mBAAA;EACA,aAAA;EACA,uBAAA;AAPR;AAWI;EAjCF;IAkCI,YAAA;EARJ;EASI;IACE,kBAAA;IACA,kBAAA;EAPN;EAQM;IACE,eAAA;EANR;EAQM;IACE,gBAAA;IACA,aAAA;IACA,sBAAA;IACA,SAAA;EANR;EAOQ;IACE,WAAA;EALV;AACF","sourcesContent":[".incdetails {\n  background: linear-gradient(180deg, #66ead093, rgba(177, 252, 225, 0) 100%),\n    url(\"../../../images/hero_bg.png\");\n  padding: 60px 5px;\n  .hero {\n    .content {\n      display: flex;\n      flex-direction: column;\n      gap: 8px;\n      .data {\n        display: flex;\n        flex-direction: column;\n        gap: 5px;\n        background: linear-gradient(\n          180deg,\n          #66ead093,\n          rgba(177, 252, 225, 0) 100%\n        );\n        border: 1px dashed #000;\n        border-radius: 10px;\n        padding: 10px;\n      }\n      .data1 {\n        display: flex;\n        flex-direction: column;\n        gap: 5px;\n        background: linear-gradient(\n          180deg,\n          #e1ea6693,\n          rgba(235, 226, 129, 0.336) 100%\n        );\n        border-radius: 10px;\n        padding: 10px;\n        border: 1px dashed #000;\n      }\n    }\n    $breakpoint-tablet: 900.1px;\n    @media (max-width: $breakpoint-tablet) {\n      padding: 5px;\n      .content {\n        padding: 10px 10px;\n        border-radius: 8px;\n        .msg {\n          font-size: 34px;\n        }\n        .quickaction {\n          margin-top: 30px;\n          display: flex;\n          flex-direction: column;\n          gap: 20px;\n          .data {\n            width: 100%;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
