import React, { useEffect, useState, useRef } from "react";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Field, Form, FormikProvider, useFormik } from "formik";
export const CreditUser = () => {
    const toast = useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ),
    amount: Yup.number().required("Invalid Amount Found."),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      amount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      //setButtonDisabled(true);
      const resp = await axios.post(
        url + "/admin/credituser",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
        if (resp.status === 200) {
          //setVisible(true)
          //localStorage.setItem("triconixemail", formik.values.email);
          // Navigate("/user");
          toast.current.show({ severity: 'success', summary: "Success", detail: "Sign up successfull." });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Invalid Credential! Try Correcting again.",
          });
        }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="requesttopupinr">
        <Toast ref={toast} />
      <div className="grid">
        <div className="col-6">
          <Card>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <h3>Credit User Balance</h3>
                  <div className="component">
                    <InputText
                      type="text"
                      name="email"
                      placeholder="Email Address"
                      autoFocus
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {getFormErrorMessage("tranid")}
                  </div>
                  <div className="component">
                    <InputText
                      type="number"
                      name="amount"
                      placeholder="Amount"
                      onChange={formik.handleChange}
                      value={formik.values.amount}
                    />
                    {getFormErrorMessage("amount")}
                  </div>
                  <div className="component">
                    {isButtonDisabled == false ? (
                      <Button type="submit" label="Submit" />
                    ) : (
                      <Button type="button" label="Submitting..." />
                    )}
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  );
};
