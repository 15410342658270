import React, { useEffect, useState, useRef } from "react";
import "./cryptodeposit.scss";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Dropdown } from "primereact/dropdown";
import { Form, FormikProvider, useFormik } from "formik";
export const CryptoDeposit = () => {
  const toast = useRef(null);
  const [buttonstate, setButtonstate] = useState(0);
  const [data, setData] = useState({});
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const network = [
    { name: "BEP20USDT", code: "B20USDT" },
    { name: "BEP20TRX", code: "B20TRX" },
    { name: "TRON", code: "TRX" },
  ];
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  var email = localStorage.getItem("triconixemail");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getcryrequests",
        {
          email,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.cryptoreq);
      }
    };
    fetchData();
  }, [url, apikey, email]);
  const validationSchema = Yup.object({
    email:Yup.mixed().required("Email Missing"),
    tranid: Yup.number("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ),
    amount: Yup.number().required("Invalid Amount Found."),
  });
  const formik = useFormik({
    initialValues: {
      email: email,
      tranid: "",
      amount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonstate(1);
      if (selectedNetwork == null) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please Select Network.",
        });
        setButtonstate(0);
        return false;
      }
      const resp = await axios.post(
        url + "/user/cryptodeposit",
        {
          values,
          selectedNetwork,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonstate(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Your Request Have been Submited.",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
        setButtonstate(0);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({ severity: 'success', summary: "Success", detail: 'Deposit Link copied.' });
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="cryptodeposit">
      <Toast ref={toast} />
      <div className="grid">
        
        <div className="col-12 md:col-12 lg:col-6">
          <Card title="Crypto Deposit Address" className="crpdepadd">
            <div className="container">
              <div className="cont">
                <div className="network">BEP20USDT</div>
                <div className="add">
                  <div className="address">
                    0xE8FFf18eDFeF83B74F165439d78A2CC03aEA19dB
                  </div>
                  <i className="pi pi-copy copy" onClick={()=>copyText("0xE8FFf18eDFeF83B74F165439d78A2CC03aEA19dB")}></i>
                  <i className="pi pi-qrcode qrcode"></i>
                </div>
              </div>
              <div className="cont">
                <div className="network">BEP20TRX</div>
                <div className="add">
                  <div className="address">
                  TBvmZs81gX9Y77m8w5pCRiDGTwAVYcdr2G
                  </div>
                  <i className="pi pi-copy copy" onClick={()=>copyText("TBvmZs81gX9Y77m8w5pCRiDGTwAVYcdr2G")}></i>
                  <i className="pi pi-qrcode qrcode"></i>
                </div>
              </div>
              <div className="cont">
                <div className="network">TRON</div>
                <div className="add">
                  <div className="address">
                  THVMWNcHpqckLzC87a3gQmrjZLj4xAXe3g
                  </div>
                  <i className="pi pi-copy copy" onClick={()=>copyText("TBvmZs81gX9Y77m8w5pCRiDGTwAVYcdr2G")}></i>
                  <i className="pi pi-qrcode qrcode"></i>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-6">
          <Card>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <h3>Crypto Deposit Details</h3>
                  <div className="component">
                    <div>
                      <Dropdown
                        name="network"
                        value={selectedNetwork}
                        onChange={(e) => setSelectedNetwork(e.value)}
                        options={network}
                        optionLabel="name"
                        placeholder="Select Network"
                        className="w-full"
                      />
                      {getFormErrorMessage("network")}
                    </div>
                  </div>
                  <div className="component">
                    <InputText
                      type="text"
                      name="tranid"
                      placeholder="Tran Hash"
                      autoFocus
                      className="w-full"
                      onChange={formik.handleChange}
                      value={formik.values.tranid}
                    />
                    {getFormErrorMessage("tranid")}
                  </div>
                  <div className="component">
                    <InputText
                      type="number"
                      name="amount"
                      placeholder="Amount Transfered"
                      className="w-full"
                      onChange={formik.handleChange}
                      value={formik.values.amount}
                    />
                    {getFormErrorMessage("amount")}
                  </div>
                  <div className="component">
                    {buttonstate === 0 ? (
                      <Button type="submit" label="Submit" className="w-full" />
                    ) : (
                      <>
                        {buttonstate === 1 ? (
                          <Button
                            type="button"
                            label="Submitting..."
                            className="w-full"
                          />
                        ) : (
                          <Button
                            type="button"
                            label="Done"
                            className="w-full"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <Card title="Request List">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Network</TableCell>
                    <TableCell align="center">Tran Hash</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        {moment(row.creation_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left">{row.network}</TableCell>
                      <TableCell align="left">{row.tranid}</TableCell>
                      <TableCell align="left">{row.amount}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
