import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Card } from "primereact/card";
import "./userfooter.scss";
import { Link, useNavigate } from "react-router-dom";
export const UserFooter = () => {
  const [data, setData] = useState([])
  const url = process.env.REACT_APP_HOST_ADDR;
  const actuser = localStorage.getItem("triconixemail");
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getdetails",
        {
          actuser,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if(resp.status===200){
        setData(resp.data.userdata.is_active);
      }
    };
    fetchData();
  },[actuser]);
  console.log(data)
  return (
    <div>
      <>
        <div className="userfooter">
          <Card>
            <div className="cont">
              <Link to="/user/">
                <img src="/icons/home.webp" alt="" />
                <div>HOME</div>
              </Link>
            </div>
            <div className="cont">
              <Link to={data===1?"/user/mybucket":''}>
                <img src="/icons/singleid.webp" alt="" />
                <div>MY BUCKETS</div>
              </Link>
            </div>
            <div className="cont">
              <Link to="/user/incomedetails">
                <img src="/icons/incomdetail.webp" alt="" style={{scale:'2.5', marginBottom:'20px'}} />
                <div>INCOMES</div>
              </Link>
            </div>
            <div className="cont">
              <Link to="/user/mylevels">
                <img src="/icons/othertopup.webp" alt=""  />
                <div>LEVELS</div>
              </Link>
            </div>
            <div className="cont">
              <Link to="#">
                <img src="/icons/withdraw.webp" alt="" />
                <div>WITHDRAW</div>
              </Link>
            </div>
          </Card>
        </div>
      </>
    </div>
  );
};
