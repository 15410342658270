// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/hero_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admdashboard {
  padding: 60px 10px;
  background: linear-gradient(180deg, rgba(102, 234, 208, 0.5764705882), rgba(177, 252, 225, 0) 100%), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/admdashboard/admdashboard.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACF,4IAAA;EAEE,iBAAA;AAAJ","sourcesContent":[".admdashboard{\n    padding: 60px 10px;\n  background: linear-gradient(180deg, #66ead093, rgba(177, 252, 225, 0) 100%),\n    url(\"../../../images/hero_bg.png\");\n    min-height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
