// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/hero_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home .hero {
  padding: 80px 20px;
  background: linear-gradient(180deg, rgba(102, 234, 208, 0.5764705882), rgba(177, 252, 225, 0) 100%), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.home .hero .branding {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.home .module {
  padding: 10px;
}
@media (max-width: 900.1px) {
  .home .hero {
    padding: 80px 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,4IAAA;AAAJ;AAEI;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;AAAN;AAGE;EACE,aAAA;AADJ;AAII;EACE;IACE,kBAAA;EAFN;AACF","sourcesContent":[".home {\n  .hero {\n    padding: 80px 20px;\n    background: linear-gradient(180deg, #66ead093, rgba(177, 252, 225, 0) 100%),\n      url(\"../../images/hero_bg.png\");\n    .branding {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      gap: 5px;\n    }\n  }\n  .module{\n    padding: 10px;\n  }\n  $breakpoint-tablet: 900.1px;\n    @media (max-width: $breakpoint-tablet) {\n      .hero{\n        padding: 80px 10px\n      }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
