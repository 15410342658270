import React, { useEffect, useState, useRef } from "react";
import "./userprofile.scss";
import { Card } from "primereact/card";
import axios from "axios";
export const UserProfile = () => {
  const [data, setData] = useState({})
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actuser = localStorage.getItem("triconixemail");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getprofile",
        {
          actuser,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      // console.log(balance);
      if (resp.status === 200) {
        setData(resp.data.profile);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="userprofile">
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-5 left">
            <Card>
              <div>
                <span>NAME</span>
                <span>{data.user_name}</span>
              </div>
              <div>
                <span>PAN</span>
                <span>{data.pan}</span>
              </div>
              <div>
                <span>AADHAAR</span>
                <span>{data.aadhar}</span>
              </div>
              <div>
                <span>ACC. No.</span>
                <span>{data.accno}</span>
              </div>
              <div>
                <span>BANK</span>
                <span>{data.bank}</span>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
