// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/hero_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.liveaccount {
  background: linear-gradient(180deg, rgba(102, 234, 208, 0.5764705882), rgba(177, 252, 225, 0) 100%), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.liveaccount .hero {
  padding: 80px 10px;
}
.liveaccount .hero .content .form-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.liveaccount .hero .content .form-group .component {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/liveaccount/liveaccount.scss"],"names":[],"mappings":"AAAA;EACE,4IAAA;AACF;AACE;EACE,kBAAA;AACJ;AACM;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACR;AAAQ;EACE,aAAA;EACA,sBAAA;AAEV","sourcesContent":[".liveaccount {\n  background: linear-gradient(180deg, #66ead093, rgba(177, 252, 225, 0) 100%),\n    url(\"../../../images/hero_bg.png\");\n  .hero {\n    padding: 80px 10px;\n    .content {\n      .form-group {\n        display: flex;\n        flex-direction: column;\n        gap: 15px;\n        .component {\n          display: flex;\n          flex-direction: column;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
