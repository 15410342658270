import React, { useEffect, useState } from "react";
import "./liveaccountreq.scss";
import { Button } from "primereact/button";
import axios from "axios";
import { Card } from "primereact/card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const LiveAccountReq = () => {
  const [data, setData] = useState({});
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/pendinglive", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.pendingbank);
      }
    };
    fetchData();
  }, [url, apikey]);
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="liveaccountreq">
      <Card>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">SL. No.</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Broker Name</TableCell>
                <TableCell align="center">Id</TableCell>
                <TableCell align="center">Password</TableCell>
                <TableCell align="center">MT4Id</TableCell>
                <TableCell align="center">MT4Pass</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Mobile</TableCell>
                <TableCell align="center">Accept</TableCell>
                <TableCell align="center">Reject</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.tranid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">
                    {moment(row.creation_date).format("YY-MMM-DD")}
                  </TableCell>
                  <TableCell align="left">{row.broker_name}</TableCell>
                  <TableCell align="left">{row.portal_id}</TableCell>
                  <TableCell align="left">{row.portal_password}</TableCell>
                  <TableCell align="left">{row.mt4_id}</TableCell>
                  <TableCell align="left">{row.mt4_password}</TableCell>
                  <TableCell align="left">{row.amount}</TableCell>
                  <TableCell align="left">{row.mobile}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      color="info"
                      //   onClick={() => approveDeposit(row.tranid, row.email, row.amount)}
                    >
                      Approve
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      severity="warning"
                      //   onClick={() => rejectDeposit(row.order_id, row.pair)}
                    >
                      Reject
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};
