import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./p2ptransfer.scss";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const P2PTransfer = () => {
  const toast = useRef(null);
  const [balance, setBalance] = useState("0.00");
  const [data, setData] = useState({});
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const email = localStorage.getItem("triconixemail");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getbalance",
        {
          email,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      const tranlist = await axios.post(
        url + "/user/p2ptransferlist",
        {
          email,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      setBalance(resp.data.balance);
      setData(tranlist.data.p2plist);
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    sendingto: Yup.mixed("Invalid email found!!!").required(
      "Receiver Email Can Not Be Blank."
    ),
    password: Yup.string()
      .min(8, "Minimum 8 Charecter Required")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      .required("Please Provide Your Password."),
    tusdt: Yup.number().required("Not Valid TUSD"),
  });
  const formik = useFormik({
    initialValues: {
      email,
      sendingto: "",
      password: "",
      tusdt: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (values.tusdt * 1 > balance * 1) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid Amount!!!",
        });
        return false;
      }
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/p2ptransfer",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2)
        toast.current.show({ severity: 'success', summary: "Success", detail: "Sign up successfull." });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="p2ptransfer">
      <Toast ref={toast} />
      <div className="head">P2P Transfer!!!</div>
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-5 left">
          <Card>
            <div className="available p-2 mb-3">
              Availavle [TUSD]: {balance}
            </div>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div className="component">
                    <InputText
                      type="email"
                      name="sendingto"
                      placeholder="Sending To Email Address"
                      autoFocus
                      onChange={formik.handleChange}
                      value={formik.values.sendingto}
                    />
                    {getFormErrorMessage("sendingto")}
                  </div>
                  <div className="component">
                    <Password
                      type="password"
                      name="password"
                      placeholder="My Password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      toggleMask
                    />
                    {getFormErrorMessage("password")}
                  </div>
                  <div className="component">
                    <InputText
                      type="number"
                      name="tusdt"
                      placeholder="TUSD"
                      onChange={formik.handleChange}
                      value={formik.values.tusdt}
                    />
                    {getFormErrorMessage("tusdt")}
                  </div>
                  <div className="component">
                    {isButtonDisabled === 0 ? (
                      <Button type="submit" label="Submit" size="large" />
                    ) : (
                      <>
                        {isButtonDisabled === 1 ? (
                          <Button
                            type="button"
                            label="Submitting"
                            size="large"
                          />
                        ) : (
                          <Button
                            type="button"
                            label="Submitted"
                            size="large"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-7 right">
          <Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Tran Type</TableCell>
                    <TableCell align="center">Note</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">{row.tran_type}</TableCell>
                      <TableCell align="center">{row.note}</TableCell>
                      {/* <TableCell align="center">
                        {row.pay_stop == 0 ? (
                          <Button
                            label="Claim"
                            onClick={() => claimCommision(row._id)}
                          />
                        ) : (
                          "Stoped"
                        )}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
