import React, { useEffect, useState, useRef } from "react";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import axios from "axios";
import "./userdashboard.scss";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Badge } from "primereact/badge";
export const UserDashBoard = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState([]);
  const [balance, setBalance] = useState(0);
  let _rank = ['NOT ACHIEVED'];
  let _salary = ['NOT ACHIEVED'];
  const actuser = localStorage.getItem("triconixemail");
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getdetails",
        {
          actuser,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      const balance = await axios.post(
        url + "/user/getbalance",
        {
          email: actuser,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.userdata);
        setBalance(balance.data.balance);
      }
    };
    fetchData();
  }, []);
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Ref Link copied.",
    });
  };
  return (
    <div className="userdashboard">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-5 left">
            <div className="p-1 border-round-sm">
              <div className="title">WELCOME TO</div>
              <div className="logotext">TRICONIX</div>
              <div className="msg">
                <Divider />
                Lets celebrate AI based, words leading technology integrated
                platform.
                <Divider />
              </div>
              {data.mail_verified == false ? (
                <>
                  <Link to="/user/mailverify">
                    <Button severity="danger" label="Mail Verify" />
                  </Link>
                </>
              ) : (
                <>
                  {data.is_active == 0 ? (
                    <Link to="/user/kycupdate">
                      <Button severity="danger" label="Complete KYC" />
                    </Link>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-7 right">
            <div className="grid">
              <div className="col-6 md:col-6 lg:col-6">
                <div className="text-center cust-bg">
                  <img src="/icons/invest.webp" width={40} alt="" />
                  <div>
                    <Link>Total Business</Link>
                  </div>
                  <div className="val">${(data.myfund*1).toFixed(2)}</div>
                </div>
              </div>
              <div className="col-6 md:col-6 lg:col-6">
                <div className="text-center cust-bg">
                  <img src="/icons/invest.webp" width={40} alt="" />
                  <div>
                    <Link to={data.is_active == 0 ? "#" : "/user/mybucket"}>
                      Total Income
                    </Link>
                  </div>
                  <div className="val">{balance.toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-12 md:col-12 lg:col-12">
                <div className="text-left bg-red-200 text-blue-150 p-3 rounded-bl-lg">
                  <span className="text-gray-700">Ref Link: </span>
                  {data.has_invested == 1 ? (
                    <>
                      <span className="reflink text-gray-700">
                        https://triconix.io/signup/{data.memberid}
                      </span>
                      <span
                        className="pi pi-copy ml-2 copy"
                        onClick={() =>
                          copyText(
                            `https://triconix.io/signup/${data.memberid}`
                          )
                        }
                      />
                    </>
                  ) : (
                    <span className="text-gray-700">
                      {" "}
                      Sorry!!! You cannot reffer.{" "}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-6 md:col-6 lg:col-6">
                <div className="text-center cust-bg">
                  <img src="/icons/invest.webp" width={40} alt="" />
                  <div>
                    <Link
                      to={data.is_active === 0 ? "#" : "/user/requesttopupinr"}
                    >
                      RANK
                    </Link>
                  </div>
                  <div className="val">{_rank[data.rank]}</div>
                </div>
              </div>
              <div className="col-6 md:col-6 lg:col-6">
                <div className="text-center cust-bg">
                  <img src="/icons/invest.webp" width={40} alt="" />
                  <div>
                    <Link
                      to={data.is_active === 0 ? "#" : "/user/cryptodeposit"}
                    >
                      SALARY
                    </Link>
                  </div>
                  <div className="val">{_salary[data.salary]}</div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-12 ">
                <div className="mydown cust-bg">
                  <div>USER:</div>
                  <div>{data.user_name}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid mydetails">
          <div className="col-12 md:col-3 lg:col-3">
            <div className="text-center cust-bg">
              <img src="/icons/invest.webp" width={40} alt="" />
              <div>
                <Link>Total Business</Link>
              </div>
              <div className="val">0.00</div>
            </div>
          </div>
          <div className="col-12 md:col-3 lg:col-3">
            <div className="text-center cust-bg">
              <img src="/icons/invest.webp" width={40} alt="" />
              <div>
                <Link>Total Income</Link>
              </div>
              <div className="val">0.00</div>
            </div>
          </div>
          <div className="col-12 md:col-3 lg:col-3">
            <div className="text-center cust-bg">
              <img src="/icons/invest.webp" width={40} alt="" />
              <div>
                <Link>Total Income</Link>
              </div>
              <div className="val">0.00</div>
            </div>
          </div>
          <div className="col-12 md:col-3 lg:col-3">
            <div className="text-center cust-bg">
              <img src="/icons/invest.webp" width={40} alt="" />
              <div>
                <Link>Total Income</Link>
              </div>
              <div className="val">0.00</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
