import React, { useEffect, useState } from "react";
import "./admindashboard.scss";
import { Link } from "react-router-dom";
import axios from "axios";
export const AdminDashboard = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState([])
  const [liveaccount, setLiveAccount] = useState(0)
  useEffect(()=>{
    const fetchData = async () => {
      const resp = await axios.get(
        url + "/admin/getdashboarddata",
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if(resp.status===200){
        setData(resp.data.totinr);
        setLiveAccount(resp.data.totlive)
      }
      
    };
    fetchData();
  },[apikey, url])
  return (
    <div className="admindashboard">
      <div className="topbar">
        <div className="grid">
          <div className="col-6 md:col-6 lg:col-2 cont">
            <div className="title">Wallet Request</div>
            <Link to="/admin/walletrequest">{data}</Link>
          </div>
          <div className="col-6 md:col-6 lg:col-2 cont">
            <div className="title">Live Account</div>
            <Link to="/admin/liveaccountreq">{liveaccount}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
