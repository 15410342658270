import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Password } from 'primereact/password';
import "./login.scss";
export const Login = () => {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [visible, setVisible] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    password: Yup.string()
      .min(8, "Minimum 8 Charecter Required")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      .required("Invalid Password."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      const resp = await axios.post(
        url + "/login",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        //setVisible(true)
        localStorage.setItem("triconixemail", formik.values.email);
        Navigate("/user");
        //toast.current.show({ severity: 'success', summary: "Success", detail: "Sign up successfull." });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid Credential! Try Correcting again.",
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="login">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/tlogo.webp" width="100" alt="" />
                    <h3>USER LOG IN</h3>
                    <div className="component">
                      <InputText
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <div className="component">
                      <Password
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        toggleMask
                      />
                      {getFormErrorMessage("password")}
                    </div>

                    <div className="component">
                      <Button type="submit" label="Submit" size="large" />
                    </div>
                    <div className="component">
                      <Link to="/forgetpassword">Forget Password?</Link>
                    </div>
                    <div className="component">
                      <p>
                        Don't have account? <Link to="/signup/1">Sign Up</Link>
                      </p>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="p-1 border-round-sm"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
