import { Divider } from "primereact/divider";
import React from "react";
export const AboutUs = () => {
  return (
    <div className="abtcontent">
      <div className="grid">
        <div className="col-12 md:col-5 lg:col-5 pb-0 mb-0">
          <img src="/images/aboutus.webp" alt="aboutus" width={'100%'} height={'100%'} />
        </div>
        <div className="col-12 md:col-7 lg:col-7 right">
            <h3>About Us</h3>
            <Divider />
            <p>triconix.io has experienced online forex professionals in your corner providing expert advice, educational tools, forex market commentary, and best practices guidance to ensure more time for your active trading. We started this triconix portal because we have a genuine interest in trading forex and have seen a lot of pitfalls and also a lot of pros and cons with different forex trading platforms.</p>
            <p>we believe that successful currency trading requires knowledge, experience and a disciplined approach to the market. Every page of this website is dedicated to these three factors in some fashion.</p>
        </div>
      </div>
    </div>
  );
};
