// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeappbar {
  padding: 10px 50px;
  position: fixed;
  width: 100vw;
  opacity: 0.7;
}
.homeappbar .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
}
.homeappbar .content .brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
}
.homeappbar .content .brand .title {
  font-weight: 900;
  font-size: 24px;
}
.homeappbar .content .brand img {
  width: 40px;
}
.homeappbar .content .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
@media (max-width: 900.1px) {
  .homeappbar {
    padding: 5px 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/homecomponents/homeappbar.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;AACJ;AACI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,UAAA;AACR;AAAQ;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,QAAA;AAEZ;AADY;EACI,gBAAA;EACA,eAAA;AAGhB;AAAY;EACI,WAAA;AAEhB;AACQ;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AACZ;AAMI;EApCJ;IAqCQ,iBAAA;EAHN;AACF","sourcesContent":[".homeappbar{\n    padding: 10px 50px;\n    position: fixed;\n    width: 100vw;\n    opacity: 0.7;\n    // background: linear-gradient(90deg, #d0ea6670, rgb(242, 255, 0) 100%);\n    .content{\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        opacity: 1;\n        .brand{\n            display: flex;\n            justify-content: space-between;\n            align-items: center;\n            gap: 3px;\n            .title{\n                font-weight: 900;\n                font-size: 24px;\n                // color: rgb(234, 255, 0);\n            }\n            img{\n                width: 40px;\n            }\n        }\n        .menu{\n            display: flex;\n            justify-content: space-between;\n            align-items: center;\n            gap: 15px;\n            .btnlogin:hover{\n                // background-color: aqua;\n            }\n        }\n    }\n    $breakpoint-tablet: 900.1px;\n    @media (max-width: $breakpoint-tablet) {\n        padding: 5px 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
