import React, { useEffect, useRef, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {Password} from 'primereact/password'
import "./livebalancereq.scss";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const LiveBalanceReq = () => {
    const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const toast = useRef(null);
  const email = localStorage.getItem("triconixemail");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [balance, setBalance] = useState("0");
  useEffect(()=>{
    const fetchData = async () => {
      const resp = await axios.post(url + "/user/getliveact",{
        email
      }, {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.liveactlist);
      }
    };
    fetchData();
  },[apikey, url, email])
  const validationSchema = Yup.object({
    brokername: Yup.mixed().required("Field is Required"),
    cliportid: Yup.mixed().required("Field is Required"),
    cliportpass: Yup.mixed().required("Field is Required"),
    mt4id: Yup.mixed().required("Field is Required"),
    mt4pass: Yup.mixed().required("Field is Required"),
    server: Yup.mixed().required("Field is Required"),
    investamount: Yup.number().required("Field is Required"),
    mobile: Yup.mixed().required("Field is Required"),
    email: Yup.mixed().required("Field is Required"),
  });
  const formik = useFormik({
    initialValues: {
      email,
      brokername:"",
      cliportid:"",
      cliportpass:"",
      mt4id:"",
      mt4pass:"",
      server:"",
      investamount:"",
      mobile:"",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      if (values.topupval * 1 > balance * 1) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid Amoun! Try Correcting again.",
        });
        setButtonDisabled(false);
        return false;
      }
      const resp = await axios.post(
        url + "/user/newliveaccount",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
        setButtonDisabled(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
        setButtonDisabled(false);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="livebalancereq">
        <Toast ref={toast} />
        <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
          <Card title="Request Live Account">
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div>
                    <InputText
                      type="text"
                      name="brokername"
                      className="p-inputtext-sm"
                      placeholder="Broker Name"
                      onChange={formik.handleChange}
                      value={formik.values.brokername}
                    />
                    {getFormErrorMessage("brokername")}
                  </div>
                  <div>
                    <InputText
                      type="text"
                      name="cliportid"
                      className="p-inputtext-sm"
                      placeholder="Client Portal Id"
                      onChange={formik.handleChange}
                      value={formik.values.cliportid}
                    />
                    {getFormErrorMessage("cliportid")}
                  </div>
                  <div>
                    <Password
                      type="password"
                      name="cliportpass"
                      className="p-inputtext-sm"
                      placeholder="Client Portal Password"
                      onChange={formik.handleChange}
                      value={formik.values.cliportpass}
                      toggleMask
                    />
                    {getFormErrorMessage("cliportpass")}
                  </div>
                  <div>
                    <InputText
                      type="text"
                      name="mt4id"
                      className="p-inputtext-sm"
                      placeholder="MT4 Id"
                      onChange={formik.handleChange}
                      value={formik.values.mt4id}
                    />
                    {getFormErrorMessage("mt4id")}
                  </div>
                  <div>
                    <Password
                      type="password"
                      name="mt4pass"
                      className="p-inputtext-sm"
                      placeholder="MT4 Password"
                      onChange={formik.handleChange}
                      value={formik.values.mt4pass}
                      toggleMask
                    />
                    {getFormErrorMessage("mt4pass")}
                  </div>
                  <div>
                    <InputText
                      type="text"
                      name="server"
                      className="p-inputtext-sm"
                      placeholder="Server"
                      onChange={formik.handleChange}
                      value={formik.values.server}
                    />
                    {getFormErrorMessage("server")}
                  </div>
                  <div>
                    <InputText
                      type="text"
                      name="investamount"
                      className="p-inputtext-sm"
                      placeholder="Amount"
                      onChange={formik.handleChange}
                      value={formik.values.investamount}
                    />
                    {getFormErrorMessage("investamount")}
                  </div>
                  <div>
                    <InputText
                      type="text"
                      name="mobile"
                      className="p-inputtext-sm"
                      placeholder="Mobile No"
                      onChange={formik.handleChange}
                      value={formik.values.mobile}
                    />
                    {getFormErrorMessage("mobile")}
                  </div>
                  {isButtonDisabled === false ? (
                    <Button type="submit">Submit</Button>
                  ) : (
                    <Button
                      type="button"
                      variant="outlined"
                      icon="pi pi-spin pi-spinner"
                    >
                      {" "}
                      Submitting..
                    </Button>
                  )}
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-6 right">
          <Card title="Request History">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date).utcOffset(330).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">
                        {row.status === 0 ? "Pending" : "Success"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  )
}
