import React, { useEffect, useRef, useState } from "react";
import "./mylevels.scss";
import moment from "moment";
import { Card } from "primereact/card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
export const MyLeves = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const toast = useRef(null);
  const email = localStorage.getItem("triconixemail");
  const initialized = useRef(false);
  useEffect(() => {
    const fetchData = async () => {
      const resplevel = await axios.post(
        url + "/user/getlevels",
        {
          email,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resplevel.status === 200) {
        setData(resplevel.data.bucket);
      }
      const respinvestor = await axios.post(
        url + "/user/claimlevelincome",
        { email },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
    };
    if (!initialized.current) {
      initialized.current = true;
      fetchData();
    }
  }, []);

  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="mylevels">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
          <Card title="My Levels">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Level</TableCell>
                    <TableCell align="center">From Id</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">%</TableCell>
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.level}</TableCell>
                      <TableCell align="center">{row.from_id}</TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">{row.percentage}</TableCell>
                      <TableCell align="center">
                        {row.pay_stop == 0 ? "Running" : "Stoped"}
                      </TableCell>
                      {/* <TableCell align="center">
                        {row.pay_stop == 0 ? (
                          <Button
                            label="Claim"
                            onClick={() => claimCommision(row._id)}
                          />
                        ) : (
                          "Stoped"
                        )}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
