import React, { useEffect, useState, useRef } from "react";
import "./requesttopupinr.scss";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import { useFormik } from "formik";
import { Form, FormikProvider, useFormik } from "formik";
export const RequestTopUpInr = () => {
  const toast = useRef(null);
  const [buttonstate, setButtonstate] = useState(0);
  const [data, setData] = useState({})
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  var email = localStorage.getItem("triconixemail");
  useEffect(()=>{
    const fetchData = async () => {
      const resp = await axios.post(url + "/user/getinrrequests",{
        email
      }, {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.inrreq);
      }
    };
    fetchData();
  },[apikey, url, email])
  const validationSchema = Yup.object({
    tranid: Yup.number("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ),
    amount: Yup.number().required("Invalid Amount Found."),
  });
  const formik = useFormik({
    initialValues: {
      email: email,
      tranid: "",
      amount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonstate(1);
      const resp = await axios.post(
        url + "/user/reqinrdeposit",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
        if (resp.status === 200) {
          setButtonstate(2)
          localStorage.setItem("triconixemail", formik.values.email);
          // Navigate("/user");
          toast.current.show({ severity: 'success', summary: "Success", detail: "Your Request Have been Submited." });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong! Try After sometimes.",
          });
          setButtonstate(0)
        }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="requesttopupinr">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6">
          <Card>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <h3>Avail INR Deposit</h3>
                  <div className="component">
                    <InputText
                      type="text"
                      name="tranid"
                      placeholder="Tran Id/UTR No."
                      autoFocus
                      onChange={formik.handleChange}
                      value={formik.values.tranid}
                    />
                    {getFormErrorMessage("tranid")}
                  </div>
                  <div className="component">
                    <InputText
                      type="number"
                      name="amount"
                      placeholder="Amount Deposited"
                      onChange={formik.handleChange}
                      value={formik.values.amount}
                    />
                    {getFormErrorMessage("amount")}
                  </div>
                  <div className="component">
                    {buttonstate === 0 ? (
                      <Button type="submit" label="Submit" />
                    ) : (
                      <>
                      {buttonstate===1?
                      <Button type="button" label="Submitting..." />
                      :
                      <Button type="button" label="Done" />
                      }
                      </>
                      
                    )}
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-6">
          <Card title="Request List">
          <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">SL. No.</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Tran ID</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.uuid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">
                    {moment(row.creation_date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="left">{row.tranid}</TableCell>
                  <TableCell align="left">{row.amount}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
