// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/hero_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup {
  background: linear-gradient(180deg, rgba(102, 234, 208, 0.5764705882), rgba(177, 252, 225, 0) 100%), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 10px;
}
.signup .hero .content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.signup .hero .content .heading {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 56px;
}
.signup .hero .content .form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.signup .hero .content .form-group .component {
  display: flex;
  flex-direction: column;
}
.signup .hero .content .signuplog {
  color: aquamarine;
}
.signup .hero .content .signuplog a {
  text-decoration: none;
  color: aquamarine;
}
@media (max-width: 900.1px) {
  .signup .hero .content {
    border-radius: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home/signup.scss"],"names":[],"mappings":"AAAA;EACE,4IAAA;EAEA,kCAAA;EACA,4BAAA;EACA,sBAAA;EACA,kBAAA;AAAF;AAEI;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAAN;AACM;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;AACR;AACM;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACR;AAAQ;EACE,aAAA;EACA,sBAAA;AAEV;AACM;EACE,iBAAA;AACR;AAAQ;EACE,qBAAA;EACA,iBAAA;AAEV;AAIE;EAEI;IACE,kBAAA;EAHN;AACF","sourcesContent":[".signup {\n  background: linear-gradient(180deg, #66ead093, rgba(177, 252, 225, 0) 100%),\n    url(\"../../images/hero_bg.png\");\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  padding: 60px 10px;\n  .hero {\n    .content {\n      display: flex;\n      flex-direction: column;\n      gap: 8px;\n      .heading {\n        display: flex;\n        flex-direction: column;\n        font-weight: 700;\n        font-size: 56px;\n      }\n      .form-group {\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n        .component {\n          display: flex;\n          flex-direction: column;\n        }\n      }\n      .signuplog {\n        color: aquamarine;\n        a {\n          text-decoration: none;\n          color: aquamarine;\n        }\n      }\n    }\n  }\n  $breakpoint-tablet: 900.1px;\n  @media (max-width: $breakpoint-tablet) {\n    .hero {\n      .content {\n        border-radius: 8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
