import React, { useContext } from "react";
import { Menubar } from "primereact/menubar";
import { Router } from "react-router-dom";
import { DarkModeContext } from "../../../context/darkModeContext";
import { Button } from "primereact/button";
import "./userappbar.scss";
import { useNavigate } from "react-router-dom";
export const Userappbar = () => {
  const items = [
    {
      label: "User",
      icon: "pi pi-user",
      items: [
        {
          label: "My Account",
          icon: "pi pi-face-smile",
          url: "/user/myaccount",
        },
        {
          label: "Profile",
          icon: "pi pi-user-edit",
          url: "/user/userprofile",
        },
        {
          label: "Downline",
          icon: "pi pi-user-edit",
          url: "/user/downline",
        },
      ],
    },
    {
      label: "Transection",
      icon: "pi pi-server",
      items: [
        {
          label: "P2P Transfer",
          icon: "pi pi-bolt",
          url: "/user/p2ptransfer",
        },
        {
          label: "INR Deposit",
          icon: "pi pi-indian-rupee",
          url: "/user/requesttopupinr",
        },
        {
          label: "Crypto Deposit",
          icon: "pi pi-bitcoin",
          url: "/user/cryptodeposit",
        },
      ],
    },
    {
      label: "Live Account",
      icon: "pi pi-twitch",
      items: [
        {
          label: "Balance Request",
          icon: "pi pi-bolt",
          url: "/user/livebalancereq",
        },
        {
          label: "Top Up",
          icon: "pi pi-server",
          url: "",
        },
        {
          label: "Details",
          icon: "pi pi-qrcode",
          url: "",
        },
      ],
    },
    {
      label: "Incomes",
      icon: "pi pi-dollar",
      items: [
        {
          label: "My Bucket",
          icon: "pi pi-bolt",
          url: "",
        },
        {
          label: "Levels",
          icon: "pi pi-server",
          url: "",
        },
        {
          label: "Live Account",
          icon: "pi pi-qrcode",
          url: "",
        },
        {
          label: "Real Estate",
          icon: "pi pi-building-columns",
          url: "",
        },
      ],
    },
    {
      label: "Wallet",
      icon: "pi pi-credit-card",
      items: [
        {
          label: "Manage Weekly",
          icon: "pi pi-bolt",
          url: "/user/manageweekly",
        },
        {
          label: "Manage Monthly",
          icon: "pi pi-bolt",
          url: "/user/managemonthly",
        },
        {
          label: "Manage Live",
          icon: "pi pi-bolt",
          url: "",
        },
        {
          label: "Manage Real State",
          icon: "pi pi-bolt",
          url: "",
        },
        {
          label: "Request Weekly",
          icon: "pi pi-bolt",
          url: "/",
        },
        {
          label: "Request Monthly",
          icon: "pi pi-bolt",
          url: "",
        },
        {
          label: "Wallet Summery",
          icon: "pi pi-bolt",
          url: "",
        },
      ],
    },
  ];
  const Navigate = useNavigate();
  const { toggle, darkMode } = useContext(DarkModeContext);
  const start = (
    <div className="flex align-items-center gap-1">
      <img alt="logo" src="/images/tlogo.webp" height="40" className="mr-2" />
      <span className="headlogo">TRICONIX</span>
    </div>
  );
  const end = (
    <div className="flex align-items-center gap-1">
      {darkMode ? (
        <span
          className="pi pi-moon"
          onClick={toggle}
          style={{ cursor: "pointer", marginRight: 20 }}
        />
      ) : (
        <span
          className="pi pi-sun"
          onClick={toggle}
          style={{ cursor: "pointer", marginRight: 20 }}
        />
      )}
      <Button
        icon="pi pi-power-off"
        size="small"
        severity="danger"
        onClick={() => logout()}
      />
    </div>
  );
  const logout = () => {
    localStorage.clear();
    Navigate("/");
  };
  return (
    <div>
      <div className="card userappbar">
        <Menubar model={items} start={start} end={end} />
      </div>
    </div>
  );
};
