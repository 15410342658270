import React, { useState, useRef, useEffect } from "react";
import "./editprofile.scss";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
export const EditProfile = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  let { email } = useParams("email");

  useEffect(() => {
    async function fetchData() {
      const resp = await axios.post(
        url + "/admin/fetchprofile",
        {
          email,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if(resp.status==200){
        formik.setFieldValue("username",resp.data.users.user_name)
        formik.setFieldValue("pan",resp.data.users.pan)
        formik.setFieldValue("aadhar",resp.data.users.aadhar)
        formik.setFieldValue("bank",resp.data.users.bank)
        formik.setFieldValue("branch",resp.data.users.branch)
        formik.setFieldValue("accno",resp.data.users.accno)
        formik.setFieldValue("ifsc",resp.data.users.ifsc)
    }
    }
    
    fetchData();
  }, []);

  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
  });
  const formik = useFormik({
    initialValues: {
      email: email,
      username: "",
      pan: "",
      aadhar: "",
      bank: "",
      branch: "",
      accno: "",
      ifsc: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      //setButtonDisabled(true);
      const resp = await axios.post(
        url + "/admin/editprofileconf",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        //setVisible(true);
        toast.current.show({ severity: 'success', summary: "Success", detail: "Sign up successfull." });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        //onClick={() => Navigate("/login")}
        autoFocus
      />
    </div>
  );
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="editprofile">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-5">
          <Card>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <img src="/images/logo.webp" width="100" alt="" />
                  <h3>USER SIGN UP</h3>
                  <div className="component">
                    <InputText
                      type="text"
                      name="email"
                      placeholder="Email Address"
                      readOnly
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {getFormErrorMessage("email")}
                  </div>
                  <div className="component">
                    <InputText
                      type="text"
                      name="username"
                      placeholder="User Name"
                      autoFocus
                      onChange={formik.handleChange}
                      value={formik.values.username}
                    />
                    {getFormErrorMessage("username")}
                  </div>
                  <div className="component">
                    <InputText
                      type="text"
                      name="pan"
                      placeholder="Pan"
                      onChange={formik.handleChange}
                      value={formik.values.pan}
                      style={{ width: "100%" }}
                    />
                    {getFormErrorMessage("pan")}
                  </div>
                  <div className="component">
                    <InputText
                      type="text"
                      name="aadhar"
                      placeholder="Aadhar"
                      onChange={formik.handleChange}
                      value={formik.values.aadhar}
                    />
                    {getFormErrorMessage("aadhar")}
                  </div>
                  <div className="component">
                    <InputText
                      type="text"
                      name="bank"
                      placeholder="Bank"
                      onChange={formik.handleChange}
                      value={formik.values.bank}
                    />
                    {getFormErrorMessage("bank")}
                  </div>
                  <div className="component">
                    <InputText
                      type="text"
                      name="accno"
                      placeholder="Account No"
                      onChange={formik.handleChange}
                      value={formik.values.accno}
                    />
                    {getFormErrorMessage("accno")}
                  </div>
                  <div className="component">
                    <InputText
                      type="text"
                      name="branch"
                      placeholder="BRANCH"
                      onChange={formik.handleChange}
                      value={formik.values.branch}
                    />
                    {getFormErrorMessage("branch")}
                  </div>
                  <div className="component">
                    <InputText
                      type="text"
                      name="ifsc"
                      placeholder="IFSC"
                      onChange={formik.handleChange}
                      value={formik.values.ifsc}
                    />
                    {getFormErrorMessage("ifsc")}
                  </div>
                  <div className="component">
                    <Button type="submit" label="Submit" size="large" />
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
      </div>
    </div>
  );
};
