import React, { useEffect, useState, useRef } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import "./steps.scss";
import * as Yup from "yup";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
export const FinalDisplay = (props) => {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [visible, setVisible] = useState(false);
  var email = localStorage.getItem("triconixemail");
  const validationSchema = Yup.object({});
  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      const resp = await axios.post(
        url + "/user/kycupload",
        {
          email: email,
          fullname: values.fullname,
          pan: values.panno,
          aadhar: values.aadhar,
          bankname: values.bankname,
          accno: values.accno,
          branch: values.branch,
          ifsc: values.ifsc,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status == 200) {
        setVisible(true)
        //Navigate("/user");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid Credential! Try Correcting again.",
        });
        setButtonDisabled(false);
      }
    },
  });
  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => Navigate("/user")}
        autoFocus
      />
    </div>
  );
  return (
    <>
      <div className="finaldisplay">
        <Toast ref={toast} />
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            className="stepone"
            onSubmit={formik.handleSubmit}
            encType="multipart/form-data"
          >
            <div className="title">
              <h2>Check and Submit: </h2>
              <p>All Fields are Required *</p>
            </div>

            <Divider />
            <div className="content">
              <div className="inputdata">
                <InputText
                  type="text"
                  name="fullname"
                  disabled
                  label="Your Full Name *"
                  onChange={formik.handleChange}
                  value={formik.values.fullname}
                />
                <InputText
                  type="text"
                  name="pan"
                  label="PAN Number *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.panno}
                />
                <InputText
                  type="text"
                  name="aadhar"
                  label="Aadhaar Number *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.aadhar}
                />
                <InputText
                  type="text"
                  name="bankname"
                  label="Bank Name *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.bankname}
                />
                <InputText
                  type="text"
                  name="accno"
                  label="Account Number *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.accno}
                />
                <InputText
                  type="text"
                  name="branch"
                  label="Branch Name *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.branch}
                />
                <InputText
                  type="text"
                  name="ifsc"
                  label="IFSC *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.ifsc}
                />
              </div>
              <div className="buton">
                <Button
                  type="button"
                  label="Previous"
                  onClick={() => props.prev(formik.values)}
                />
                {isButtonDisabled === false ? (
                  <Button type="submit" label="Submit" />
                ) : (
                  <Button type="button" label="Submitting" />
                )}
              </div>
            </div>
          </Form>
        </FormikProvider>
        <Dialog
        visible={visible}
        modal
        header="Success"
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">Congratulation KYC Verified Successfully.</p>
        <p className="m-0">Enjoy Trading.</p>
      </Dialog>
      </div>
    </>
  );
};
