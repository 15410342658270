// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/hero_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mybusiness {
  padding: 60px 5px;
  background: linear-gradient(180deg, rgba(102, 234, 208, 0.5764705882), rgba(177, 252, 225, 0) 100%), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.mybusiness .hero .content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
}
.mybusiness .hero .content .data .p-card-body {
  padding: 3px;
  background: #03fbc9;
  border-radius: 5px;
}
.mybusiness .hero .content .data .p-card-body .p-card-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.mybusiness .hero .content .data .p-card-body .p-card-content div {
  display: flex;
  justify-content: space-between;
}
.mybusiness .hero .content .data .p-card-body .p-card-content .details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mybusiness .hero .content .data .p-card-body .p-card-content .buttons {
  border: 1px dashed rgba(98, 101, 100, 0.5764705882);
  padding: 5px;
  border-radius: 8px;
}
@media (max-width: 900.1px) {
  .mybusiness .hero {
    padding: 5px;
  }
  .mybusiness .hero .content {
    padding: 10px 5px;
    border-radius: 10px;
  }
  .mybusiness .hero .content .data {
    border-radius: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/user/mybusiness/mybusiness.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,4IAAA;AACF;AAMI;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kCAAA;EACA,4BAAA;EACA,sBAAA;EACA,mBAAA;AAJN;AAMQ;EACE,YAAA;EACA,mBAAA;EACA,kBAAA;AAJV;AAKU;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAHZ;AAIY;EACE,aAAA;EACA,8BAAA;AAFd;AAIY;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAFd;AAIY;EACE,mDAAA;EACA,YAAA;EACA,kBAAA;AAFd;AASI;EArCF;IAsCI,YAAA;EANJ;EAOI;IACE,iBAAA;IACA,mBAAA;EALN;EAMM;IACE,kBAAA;EAJR;AACF","sourcesContent":[".mybusiness {\n  padding: 60px 5px;\n  background: linear-gradient(\n          180deg,\n          #66ead093,\n          rgba(177, 252, 225, 0) 100%\n        ),\n        url(\"../../../images/hero_bg.png\");\n  .hero {\n    .content {\n      display: flex;\n      flex-direction: column;\n      gap: 8px;\n      background-position: center center;\n      background-repeat: no-repeat;\n      background-size: cover;\n      border-radius: 30px;\n      .data {\n        .p-card-body {\n          padding: 3px;\n          background: #03fbc9;\n          border-radius: 5px;\n          .p-card-content {\n            display: flex;\n            flex-direction: column;\n            gap: 8px;\n            div {\n              display: flex;\n              justify-content: space-between;\n            }\n            .details {\n              display: flex;\n              flex-direction: column;\n              gap: 10px;\n            }\n            .buttons {\n              border: 1px dashed #62656493;\n              padding: 5px;\n              border-radius: 8px;\n            }\n          }\n        }\n      }\n    }\n    $breakpoint-tablet: 900.1px;\n    @media (max-width: $breakpoint-tablet) {\n      padding: 5px;\n      .content {\n        padding: 10px 5px;\n        border-radius: 10px;\n        .data {\n          border-radius: 5px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
