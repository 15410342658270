import React, { useEffect, useState, useRef } from "react";
import "./walletrequest.scss";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const WalletRequest = () => {
  const [data, setData] = useState({});
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [visible, setVisible] = useState(false);
  const [tranid, setTranId] = useState(false);
  const [email, setEmail] = useState(false);
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/pendingbank", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.pendingbank);
      }
    };
    fetchData();
  }, []);
  const approveDeposit = (tranid, email, amt) => {
    setAmount(amt / 80)
    setTranId(tranid);
    setEmail(email);
    setVisible(true);
  };
  const confirmTran = async () => {
    axios
      .post(
        url + "/admin/approvebank",
        { tranid, email, amount },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      )
      .then(function (resp) {
        setAmount(0)
        setEmail("")
        setVisible(false)
        window.location.reload(true)
      });
  };
  const rejectDeposit = () => {};
  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => confirmTran()}
        autoFocus
      />
    </div>
  );
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="walletrequest">
      <Card>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">SL. No.</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Tran ID</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Approve</TableCell>
                <TableCell align="center">Reject</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.tranid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">
                    {moment(row.creation_date).format("YY-MMM-DD")}
                  </TableCell>
                  <TableCell align="left">{row.tranid}</TableCell>
                  <TableCell align="left">{row.user_name}</TableCell>
                  <TableCell align="left">{row.amount}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => approveDeposit(row.tranid, row.email, row.amount)}
                    >
                      Approve
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => rejectDeposit(row.order_id, row.pair)}
                    >
                      Reject
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Dialog
        visible={visible}
        modal
        header="Approve and Transfer"
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="form-grp">
          <div>Credit Coin to: {email}</div>
          <InputText
            name="amount"
            placeholder="Credit Amount"
            autoFocus
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
          />
        </div>
      </Dialog>
    </div>
  );
};
