import React, { useContext } from "react";
import "./homeappbar.scss";
import { Menubar } from "primereact/menubar";
import { DarkModeContext } from "../../../context/darkModeContext";
import { Button } from "primereact/button";
export const HomeAppbar = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const start = (
    <div className="flex align-items-center gap-1">
      <img
        alt="logo"
        src="/images/tlogo.webp"
        height="40"
        className="mr-2"
      />
      <span className="headlogo">TRICONIX</span>
    </div>
  );
  const end = (
    <div className="flex align-items-center gap-1">
      {darkMode ? (
        <span
          className="pi pi-moon"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <span
          className="pi pi-sun"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        />
      )}
      <Button
        label="Log In"
        icon="pi pi-users"
        severity="info"
        text
        size="small"
        onClick={() => (window.location.href = "/login")}
      />
      <Button
        label="Register"
        icon="pi pi-user-plus"
        size="small"
        severity="warning"
        onClick={() => (window.location.href = "/signup/619197")}
      />
    </div>
  );
  return (
    <div>
      <div className="card homeappbar">
        <Menubar start={start} end={end} />
      </div>
    </div>
  );
};
