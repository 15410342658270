import React, { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import "./steps.scss"
import * as Yup from "yup";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Cookies from "js-cookie";
export const StepOne = (props) => {
  const [checktry, setCheckTry] = useState(3);
  const [btnloading, setBtnloading] = useState(false);
  const [btnstate, setBtnState] = useState(0);
  const pancheck = Cookies.get("pancheck");
  const apikey = process.env.REACT_APP_APIKEY;
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    fullname: Yup.string("Invalid Name found").required(
      "Field can not be empty."
    ),
    panno: Yup.string("Invalid Pan Number found")
      .min(10, "Invalid Pan")
      .max(10, "Invalid Pan")
      .required("Field can not be empty."),
  });
  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: async (values) => {
      props.next(values);
    },
  });
  const panCheck = () => {
    formik.setFieldValue("panfetch", 1);
    const panno = formik.values.panno;
    if (panno == "" || panno.length < 10) {
      //notify("INVALID PAN NUMBER DETECTED.");
      formik.setFieldValue("panfetch", 0);
      return false;
    }
    if (checktry > 0 && pancheck != "0") {
      setCheckTry(checktry - 1);
      axios
        .post(url + "/user/checkpan", {
          panno,
        },{
          headers:{
            "x-api-key" : apikey
          }
        })
        .then(function (response) {
          formik.setFieldValue("fullname", response.data.fullname);
          formik.setFieldValue("panfetch", 2);
        })
        .catch(function (error) {
          formik.setFieldValue("panfetch", 0);
          console.log(error);
        });
    } else {
      //notify("You have crossed Max Check Limit! Contact support.");
      Cookies.set("pancheck", "0");
    }
  };
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="stepwreper">
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          className="stepone"
          onSubmit={formik.handleSubmit}
        >
          <div className="title">
            <h2>Enter Personal Info: </h2>
            <p>All Fields are Required *</p>
          </div>
          <Divider />
          <div className="content">
            <div className="inputdata">
              <div className="counter">
                <p>Try Left:</p>
                <p>{checktry}</p>
              </div>
              <InputText
                type="text"
                name="panno"
                placeholder="Enter Pan No."
                size="small"
                autoFocus
                onChange={formik.handleChange}
                value={formik.values.panno}
              />
              {getFormErrorMessage("panno")}
              {formik.values.panfetch == 0 ? (
                <Button variant="outlined" onClick={panCheck}>
                  Check Pan
                </Button>
              ) : (
                <>
                  {formik.values.panfetch == 1 ? (
                    <Button variant="outlined" >
                      Fetching Data
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                    >
                      Data Fetched
                    </Button>
                  )}
                </>
              )}
              {/* {btnloading == false ? (
                  <Button variant="outlined" onClick={panCheck}>
                    Check Pan
                  </Button>
                ) : (
                  <Button variant="outlined" startIcon={<CircularProgress />}>
                    Check Pan
                  </Button>
                )} */}
              <InputText
                type="text"
                name="fullname"
                placeholder="Full Name"
                readOnly
                onChange={formik.handleChange}
                value={formik.values.fullname}
              />
              {getFormErrorMessage("fullname")}
            </div>

            <div className="buton">
              <Button
                variant="contained"
                type="submit"
              >
                NEXT
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};
