import React, { useState } from "react";
import "./kycupdate.scss";
import { Card } from "primereact/card";
import { StepOne } from "../../../components/kyccomponent/StepOne";
import { StepTwo } from "../../../components/kyccomponent/StepTwo";
import { StepThree } from "../../../components/kyccomponent/StepThree";
import { FinalDisplay } from "../../../components/kyccomponent/FinalDisplay";
export const KycUpdate = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    fullname: "",
    panno: "",
    aadhar: "",
    state: "",
    accno: "",
    ifsc: "",
    bankname: "",
    branch: "",
    bankstate: "",
    city: "",
    panfetch:0,
    aadharfetch:0,
    bankfetch:0,
    // panimage: null,
    // aadharfrontimage: null,
    // aadharback: null,
    // selfy: null,
    // cheque:null,
    // pandata: "",
    // aadharfrontdata:'',
    // aadharbackdata:'',
    // selfydata:'',
    // chequedata:'',
  });
  const handleNext = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const steps = [
    <StepOne next={handleNext} data={data} />,
    <StepTwo next={handleNext} prev={handleBack} data={data} />,
    <StepThree next={handleNext} prev={handleBack} data={data} />,
    <FinalDisplay next={handleNext} prev={handleBack} data={data} />,
  ];
  return (
    <div className="kycupdate">
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-5">
          <Card>{steps[activeStep]}</Card>
        </div>
      </div>
    </div>
  );
};
