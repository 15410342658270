import React, { useEffect, useState, useRef } from "react";
import "./home.scss";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { HomeProduct } from "../../components/homecomponent/homeproduct/HomeProduct";
import { AboutUs } from "../../components/homecomponent/homeproduct/AboutUs";
export const Home = () => {
  return (
    <div className="home">
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-7 left">
            <div className="p-1 border-round-sm">
              <div className="title">WELCOME TO</div>
              <div className="logotext">TRICONIX</div>
              <div className="msg">
                <Divider />
                Lets celebrate AI based, words leading technology integrated
                platform.
                <Divider />
              </div>
              <div className="button">
                <Button
                  label="Log In"
                  icon="pi pi-users"
                  severity="info"
                  text
                  size="small"
                  onClick={() => (window.location.href = "/login")}
                />
                <Button
                  label="Register"
                  icon="pi pi-user-plus"
                  size="small"
                  severity="warning"
                  onClick={() => (window.location.href = "/signup/619197")}
                />
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-5 right">
            <div className="p-1 border-round-sm"></div>
          </div>
        </div>
      </div>
      <div className="products">
        <HomeProduct />
      </div>
      <div className="aboutus">
        <AboutUs />
      </div>
    </div>
  );
};
