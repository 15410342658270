import React, { useEffect, useState, useRef } from "react";
import { Badge } from 'primereact/badge';
import "./downline.scss";
import axios from "axios";
import { Button } from "primereact/button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const Downline = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState([]);
  const actuser = localStorage.getItem("triconixemail");
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getdownline",
        {
          actuser,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.userdata);
        //setBalance(balance.data.balance);
      }
    };
    fetchData();
  }, []);
  const createChild = (index, memberid, e) => {
    e.currentTarget.className = "p-button p-button-danger";
    e.currentTarget.disabled = true;
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getdownline",
        {
          actuser: memberid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        var tempdata = resp.data.userdata;
        for (let j = 0; j < tempdata.length; j++) {
          setData((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(index * 1 + 1, 0, resp.data.userdata[j]);
            return newArray;
          });
        }
      }
    };
    fetchData();
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="downline">
      <div className="container">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Member Name</TableCell>
                <TableCell align="center">Member Id</TableCell>
                <TableCell align="center">Down</TableCell>
                <TableCell align="center">My Fund</TableCell>
                <TableCell align="center">Business</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.memberid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  style={{backgroundColor:`#${row.join_under_id}`}}
                  className={`row.join_under_id`}
                >
                  <TableCell align="center"><Badge value={row.tran_date} /></TableCell>
                  <TableCell align="center"><Badge value={row.user_name} /></TableCell>
                  <TableCell align="center"><Badge value={row.memberid} /></TableCell>
                  <TableCell align="center">
                    {row.mydown > 0 ? (
                      <Button
                        label={row.mydown}
                        id={row.email}
                        onClick={(e) => createChild(index, row.email, e)}
                      />
                    ) : (
                      <Button
                        label="0"
                        id={row.email}
                        severity="danger"
                      />
                    )}
                  </TableCell>
                  <TableCell align="center"><Badge value={row.myfund} /></TableCell>
                  <TableCell align="center"><Badge value={row.teamfund} /></TableCell>
                  <TableCell align="center"><Badge value={row.is_active} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {rows.map((row, index) => (
          <div key={row.memberid} className="cont">
            <div className="memid">{row.memberid}</div>
            <div className="down">
              {row.mydown > 0 ? (
                <Button
                  label={row.mydown}
                  id={row.email}
                  onClick={(e) => createChild(index, row.email, e)}
                />
              ) : (
                <Button label={row.mydown}
                id={row.email} severity="danger" />
              )}
            </div>
            <div className="myfund">{row.myfund}</div>
            <div className="myfund">{row.teamfund}</div>
            <div className="myfund">{row.join_under_id}</div>
          </div>
        ))} */}
      </div>
    </div>
  );
};
