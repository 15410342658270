import React, { useEffect, useState, useRef } from "react";
import "./allusers.scss";
import axios from "axios";
import { Card } from "primereact/card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const AllUsers = () => {
  const [users, setUsers] = useState();
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getallusers", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setUsers(resp.data.users);
      }
    };
    fetchData();
  }, []);
  if (!users) return false;
  var rows = [];
  for (let i = 0; i < users.length; i++) {
    rows.push(users[i]);
  }
  return (
    <div className="allusers">
      <Card>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">SL. No.</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Member ID</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Password</TableCell>
                <TableCell align="center">Join Under</TableCell>
                <TableCell align="center">Mail Verified?</TableCell>
                <TableCell align="center">Active?</TableCell>
                <TableCell align="center">Invested?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.tranid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">
                    {moment(row.creation_date).format("YY-MMM-DD")}
                  </TableCell>
                  <TableCell align="left">{row.memberid}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.password}</TableCell>
                  <TableCell align="left">{row.join_under_id}</TableCell>
                  <TableCell align="left">
                    {row.mail_verified == true ? "YES" : "NO"}
                  </TableCell>
                  <TableCell align="left">{row.is_active}</TableCell>
                  <TableCell align="left">{row.has_invested}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};
