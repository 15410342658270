import React, { useEffect, useRef, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import "./mybucket.scss";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const MyBucket = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const toast = useRef(null);
  const email = localStorage.getItem("triconixemail");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [balance, setBalance] = useState("0");
  const initialized = useRef(false)
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getbalance",
        {
          email,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      const respbucket = await axios.post(
        url + "/user/getbuckets",
        {
          email,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setBalance(resp.data.balance);
        setData(respbucket.data.bucket);
      }
      const respinvestor = await axios.post(
        url + "/user/claiminvest",
        { email },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
    };
    if (!initialized.current) {
      initialized.current = true
      fetchData();
    }
    
  }, []);
  const validationSchema = Yup.object({
    topupval: Yup.number("Invalid Topup Value")
      .required("Top-Up Id cant not be blank.")
      .min(50),
  });
  const formik = useFormik({
    initialValues: {
      email: email,
      topupval: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      if (values.topupval * 1 > balance * 1) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid Amoun! Try Correcting again.",
        });
        setButtonDisabled(false);
        return false;
      }
      const resp = await axios.post(
        url + "/user/newbucket",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
        const newresp = await axios.post(
          url + "/user/getbalance",
          {
            email,
          },
          {
            headers: {
              "x-api-key": apikey,
            },
          }
        );
        const respbucket = await axios.post(
          url + "/user/getbuckets",
          {
            email,
          },
          {
            headers: {
              "x-api-key": apikey,
            },
          }
        );
        setData(respbucket.data.bucket);
        setBalance(newresp.data.balance);
        setButtonDisabled(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
        setButtonDisabled(false);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  // const claimCommision = async (_id) => {
  //   //alert(_id)
  //   const resp = await axios.post(
  //     url + "/user/claimmonthly",
  //     { _id },
  //     {
  //       headers: {
  //         "x-api-key": apikey,
  //       },
  //     }
  //   );
  // };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="mybucket">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
          <Card title="New Bucket">
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div className="avail">Available Balance: {balance}</div>
                  <div>
                    <div>Topup Value</div>
                    <InputText
                      type="number"
                      name="topupval"
                      className="p-inputtext-sm"
                      placeholder="Top Up Value"
                      onChange={formik.handleChange}
                      value={formik.values.topupval}
                    />
                    {getFormErrorMessage("topupval")}
                  </div>
                  {isButtonDisabled === false ? (
                    <Button type="submit">Submit</Button>
                  ) : (
                    <Button
                      type="button"
                      variant="outlined"
                      icon="pi pi-spin pi-spinner"
                    >
                      {" "}
                      Submitting..
                    </Button>
                  )}
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-6 right">
          <Card title="My Buckets">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date).utcOffset(330).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">
                        {row.pay_stop == 0 ? "Running" : "Stoped"}
                      </TableCell>
                      {/* <TableCell align="center">
                        {row.pay_stop == 0 ? (
                          <Button
                            label="Claim"
                            onClick={() => claimCommision(row._id)}
                          />
                        ) : (
                          "Stoped"
                        )}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
