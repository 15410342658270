import React, { useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import "./steps.scss";
import * as Yup from "yup";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Cookies from "js-cookie";
export const StepThree = (props) => {
  const [checktry, setCheckTry] = useState(3);
  const [btnloading, setBtnloading] = useState(false);
  const bankcheck = Cookies.get("bankcheck");
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const validationSchema = Yup.object({
    accno: Yup.number("Invalid Account number").required(
      "Field can not be empty."
    ),
    ifsc: Yup.mixed("").required("Field can not be empty."),
    bankname: Yup.mixed("").required("Field can not be empty."),
    branch: Yup.mixed("").required("Field can not be empty."),
    bankstate: Yup.mixed("").required("Field can not be empty."),
    city: Yup.mixed("").required("Field can not be empty."),
  });

  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: async (values) => {
      props.next(values);
    },
  });
  const bankCheck = () => {
    const { accno, ifsc } = formik.values;
    formik.setFieldValue("bankfetch", 1);
    if (accno == "" || ifsc == "") {
      //notify("INVALID BANK DETAILS DETECTED.");
      formik.setFieldValue("bankfetch", 0);
      return false;
    }
    if (checktry > 0 && bankcheck != "0") {
      setCheckTry(checktry - 1);
      axios
        .post(url + "/user/checkbank", {
          accno,
          ifsc,
        },{
          headers:{
            "x-api-key" : apikey
          }
        })
        .then(function (respdata) {
          formik.setFieldValue("bankname", respdata.data.respdata.bank_name);
          formik.setFieldValue("branch", respdata.data.respdata.branch);
          formik.setFieldValue("bankstate", respdata.data.respdata.state);
          formik.setFieldValue("city", respdata.data.respdata.city);
          formik.setFieldValue("bankfetch", 2);
        })
        .catch(function (error) {
          formik.setFieldValue("bankfetch", 0);
          console.log(error);
        });
    } else {
      //notify("You have crossed Max Check Limit! Contact support.");
      Cookies.set("bankcheck", "0");
    }
  };
  //const notify = (msg) => toast(msg);
  return (
    <>
      {/* <ToastContainer /> */}
      <div className="stepwreper">
        <FormikProvider value={formik}>
          <Form autoComplete="off" className="stepone">
            <div className="title">
              <h2>Enter Bank Details</h2>
            </div>
            <div className="content">
              <div className="inputdata">
                <div className="counter">
                  <p>Try Left:</p>
                  <p>{checktry}</p>
                </div>
                <InputText
                  type="text"
                  name="accno"
                  autoFocus
                  placeholder="Account Number *"
                  onChange={formik.handleChange}
                  value={formik.values.accno}
                />
                <InputText
                  type="text"
                  name="ifsc"
                  placeholder="IFSC *"
                  onChange={formik.handleChange}
                  value={formik.values.ifsc}
                />
                {formik.values.bankfetch == 0 ? (
                  <Button label="Check Bank" onClick={bankCheck} />
                ) : (
                  <>
                    {formik.values.bankfetch == 1 ? (
                      <Button label="Fetching Data" />
                    ) : (
                      <Button label="Data Fetched"
                      />
                    )}
                  </>
                )}
                {/* {btnloading==false?
                <Button variant="outlined" onClick={bankCheck}>
                Check Bank
              </Button>
                :
                <Button variant="outlined" startIcon={<CircularProgress />} >
                  Check Bank
                </Button>
                } */}
                <InputText
                  type="text"
                  name="bankname"
                  placeholder="Bank Name *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.bankname}
                />
                <InputText
                  type="text"
                  name="branch"
                  placeholder="Branch Name *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.branch}
                />
                <InputText
                  type="text"
                  name="bankstate"
                  placeholder="State *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.bankstate}
                />
                <InputText
                  type="text"
                  name="city"
                  placeholder="city *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
              </div>
              <div className="buton">
                <Button
                  type="button"
                  label="Previous"
                  onClick={() => props.prev(formik.values)}
                />
                <Button
                  label="NEXT"
                  type="submit"
                />
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
