import React from "react";
import "./incomedetails.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
export const IncomeDetails = () => {
  return (
    <div className="incomedetails">
      <div className="head">
          Income Details!!!
      </div>
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-3">
          <Card className="cust-card">
            <div className="title">Bucket Income</div>
            <div className="value">0.00</div>
            <Button label="Details" text />
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-3">
          <Card className="cust-card">
            <div className="title">Level Income</div>
            <div className="value">0.00</div>
            <Button label="Details" text />
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-3">
          <Card className="cust-card">
            <div className="title">Monthly Salary</div>
            <div className="value">0.00</div>
            <Button label="Details" text />
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-3">
          <Card className="cust-card">
            <div className="title">Reward</div>
            <div className="value">0.00</div>
            <Button label="Details" text />
          </Card>
        </div>
      </div>
    </div>
  );
};
