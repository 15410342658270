import React from "react";
import "./settings.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
export const Settings = () => {
  return (
    <div className="settings">
      <div className="head">Settings!!!</div>
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-3">
          <Card className="cust-card">
            <Link to="/user/p2ptransfer">
              <Button label="P2P TRANSFER" text />
            </Link>
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-3">
          <Card className="cust-card">
            <Button label="MY PROFILE" text />
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-3">
          <Card className="cust-card">
            <Link to="/user/manageweekly">
            <Button label="MANAGE WEEKLY" text />
            </Link>
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-3">
          <Card className="cust-card">
          <Link to="/user/managemonthly">
            <Button label="MANAGE MONTHLY" text />
            </Link>
          </Card>
        </div>
      </div>
    </div>
  );
};
