import React, { useEffect, useState, useRef } from "react";
import "./homefooter.scss";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
export const HomeFooter = () => {
  return (
    <div className="homefooter">
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-5 left">
          <div className="p-1 border-round-sm">
            <h3>Follow Us</h3>
            <Divider />
            <div className="links mb-3">
              <Link>
                <i className="pi pi-telegram mr-3"></i>
              </Link>
              <Link>
                <i className="pi pi-whatsapp mr-3"></i>
              </Link>
              <Link>
                <i className="pi pi-twitter mr-3"></i>
              </Link>
              <Link>
                <i className="pi pi-instagram mr-3"></i>
              </Link>
            </div>
            <div className="email">
              <Link to="mailto:support@triconix.io">support@triconix.io</Link>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="copyright bg-pink-50 p-3 rounded text-center">All right reserved @ triconix.io</div>
    </div>
  );
};
