import React from 'react'
import "./admdashboard.scss"
export const AdmDashBoard = () => {
  return (
    <div className='admdashboard'>
      <div className="hero">
        <div className="content">

        </div>
      </div>
    </div>
  )
}
